<template>
	<div class="enterprise">
		<div class="card">
			<div class="card_cont">
				<div class="top_info" style='margin-top: -20px;'>
					<div class="title_name">通讯录可展示字段</div>
					<div class="title_btn">
						<a-icon v-auth="'change'" :style="{color: '#397dee'}" type="form" @click='go_FieldSettings' />

						<span v-auth="'change'" class="cq_text_icon cq_color" @click='go_FieldSettings'>编辑</span>
					</div>
				</div>
				<div v-if="mailInfo_list.length>0">
					<p class="cq_text_tips">
						设置后，员工可在微信端企业通讯录中查询员工相应字段的信息。
					</p>
					<div>

						<p class="info" :key='index' v-for="(item,index) in mailInfo_list[0].value"> {{item.value}}</p>
					</div>
				</div>
				<div class="top_info">
					<div class="title_name">允许查看通讯录的员工</div>
					<div class="title_btn" @click='ViewBookEmployeesVist=true'>
						<a-icon v-auth="'change'" :style="{color: '#397dee'}" type="form" />
						<span v-auth="'change'" class="cq_text_icon cq_color">编辑</span>
					</div>
				</div>
				<div>
					<p class="cq_text_tips">
						设置后，只有选中的员工性质下的员工能够查看企业通讯录。
					</p>
					<div v-if="mailInfo_list.length>0 && !ViewBookEmployeesVist">
						<a-form :label-col="labelColEdit" :wrapper-col="wrapperCol" :model="formState">
							<a-form-item :key='index' v-for="(item,index) in mailInfo_list[1].value"
								:label="item.label">
								<p class="info" style="margin-top: -3px;"> {{item.value}}</p>
							</a-form-item>
						</a-form>
					</div>

					<div class="mailInfoEdit" v-show="ViewBookEmployeesVist">
						<a-form :label-col="labelColEdit" :wrapper-col="wrapperCol" :model="formState">
							<a-form-item label="按工作性质">
								<div id="" style=" display: flex; flex-wrap: wrap;margin-left: 20px; ">
									<span :key='index' v-for="(item,index) in select_list2"
										@click="setChoose(item.value)"
										:class="['chooseItem',chooseList.indexOf(String(item.value))>-1?'chooseItemC':'']">{{item.label}}
										<!-- <img src="../../image/choose.png" v-if="chooseList.indexOf(item.value)>-1"
											class="chooseItemactieve"> -->
									</span>

								</div>
							</a-form-item>
							<a-form-item label="按部门">

								<a-radio-group name="radioGroup" v-model:value="ViewBookEmployeestype"
									@change='changeitem'>
									<div style="display: flex;align-items: center;margin-left: 20px;">
										<a-radio value="1">全公司</a-radio>
										<a-radio value="2">指定部门 </a-radio>
									</div>
									<div v-show="ViewBookEmployeestype==2" style="margin-top: 12px;margin-left: 20px;">
										<Form ref="form_data" :form_data="form_data">
										</Form>
									</div>
									<!-- <div v-if="ViewBookEmployeestype==2 && departmentDataFilter.length>0">
										已选择<span class="cq_text_warring">{{departmentDataFilter[0].name}}</span>等<span
											class="cq_text_warring"> {{departmentDataFilter.length}} </span>个部门 ；
									</div> -->
								</a-radio-group>

							</a-form-item>


						</a-form>
						<div style="padding-top: 20px;margin-left:80px;">
							<a-button class="reset pointerCancel" style='padding: 0 12px;margin-lefy:12px;'
								@click='ViewBookEmployeesVist=false'>取消
							</a-button>
							<a-button class="reset pointer" @click='set_ViewBookEmployees' type="primary">保存
							</a-button>
						</div>
					</div>

				</div>

				<div class="top_info">
					<div class="title_name">通讯录查看范围</div>
					<div class="title_btn" @click='ViewRangeTypeVisit=true'>


						<a-icon v-auth="'change'" :style="{color: '#397dee'}" type="form" />
						<span v-auth="'change'" class="cq_text_icon cq_color">编辑</span>
					</div>
				</div>
				<div id="">
					<p class="cq_text_tips">
						设置通讯录的查看范围，在查看范围外的员工将不会展示在通讯录中。
					</p>
					<div id="" v-if="mailInfo_list.length>0 && !ViewRangeTypeVisit">
						<p class="info" v-for="(item,index) in mailInfo_list[2].value" :key='index'> {{item.label}}</p>
					</div>
					<div v-if="ViewRangeTypeVisit">
						<div class="mailInfoEdit">
							<a-radio-group name="radioGroup" v-model:value="ViewRangeType">
								<a-radio value="1">全公司</a-radio>
								<a-radio value="2">员工所在部门</a-radio>
								<a-radio value="3">员工所在部门一级</a-radio>
							</a-radio-group>
							<div style="padding-top: 20px;margin-left: 60px;">
								<a-button style='margin-lefy:12px' class="reset pointer pointerCancel"
									@click='ViewRangeTypeVisit=false'>取消
								</a-button>
								<a-button class="reset pointer" @click='setRangeType' type="primary">保存
								</a-button>
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>
		<!-- <OnlySelectDepartment ref="deptSelect" @ok="selectDepartmentOk" /> -->
	</div>
</template>

<script>
	import {
		getManageInfo,
		getBookShowField,
		bookFieldStart,
		bookFieldForbid,
		bookFieldSort,
		setBookViewRange,
		getBookRangeSelect,
		getViewBookSelect,
		setViewBookEmployees
	} from '@/api/enterprise.js'
	import {
		getSelfData
	} from '@/api/public'
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	import Form from "@/components/Form";
	export default {
		components: {

			Form
		},
		data() {
			return {
				formState: {},
				select_list2: [],
				ViewRangeType: '1',
				ViewBookEmployeestype: '1',
				ViewRangeTypeVisit: false,
				ViewBookEmployeesVist: false,
				departmentDataFilter: [],
				labelColEdit: {
					span: 2
				},

				wrapperCol: {
					span: 22
				},

				scaleType: [],
				select_list: [],
				write_off_reason: [],
				chooseList: [],
				form_data: {
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "tree-select",
							name: "department_id",
							title: "",
							options: {},
							treeData: [],
							multiple: true
						},

					]
				},
				mailInfo_list: [
					[],
					[],
					[]
				],
				initialValueArr: []
			}

		},
		created() {
			this.$method.get_department().then(res => {
				this.form_data.list.forEach(item => {
					let temp_arr = [{
						key: 0,
						value: 0,
						title: '全部',
					}]
					if (item.name == "department_id" || item.name == "department_id1") {
						item.treeData = [...temp_arr, ...res];
					}
				});
			});
			this.get_info();
			this.get_ViewBookSelect()
		},
		methods: {
			set_ViewBookEmployees() {

				// let department_ids = [];

				// this.departmentDataFilter.forEach(item => {
				// 	department_ids.push(item.id)
				// })

				Promise.all([this.$refs.form_data.submit()]).then(result => {
					console.log(result);
					let obj = {
						"type": this.ViewBookEmployeestype, //类型 1全公司 2指定范围
						"department_ids": this.ViewBookEmployeestype == 2 ? result[0].department_id.join(',') :
							'', //部门id集合
						"nature": this.chooseList.join(',') //工作性质id集合
					}
					setViewBookEmployees({
						data: {
							...obj
						}
					}).then(res => {
						message.success('操作成功');
						this.get_info();
						this.ViewBookEmployeesVist = false
					})
				})

			},

			setChoose(id) {
				if (this.chooseList.indexOf(String(id)) > -1) {
					this.chooseList.splice(this.chooseList.indexOf(String(id)), 1)
				} else {
					this.chooseList.push(String(id))
				}
			},
			get_ViewBookSelect() {
				getViewBookSelect().then(res => {
					this.select_list2 = res.data.list
				})

			},
			setRangeType() {
				setBookViewRange({
					data: {
						type: this.ViewRangeType
					}
				}).then(res => {
					message.success('操作成功');
					this.get_info();
					this.ViewRangeTypeVisit = false
				})
			},
			selectDepartmentClick() {
				this.$refs.deptSelect.visible = true;
			},
			selectDepartmentOk(datas) {
				this.departmentDataFilter = datas;
			},
			go_FieldSettings() {
				this.$router.push({
					path: '/FieldSettings'
				})
			},
			set_dis() {
				this.disabled = false
			},
			changeitem() {
				if (this.ViewBookEmployeestype == '2') {
					this.set_info()
				}
			},
			set_info() {
				this.$refs.form_data.setFieldsValue({
					department_id: this.initialValueArr
				});
			},
			get_info() {
				let that = this
				getManageInfo({
					data: {}
				}).then(res => {
					this.mailInfo_list = res.data.list
					this.chooseList = res.data.list[1].value[0].data
					this.ViewRangeType = res.data.list[2].value[0].value
					if (res.data.list[1].value[1].data) {
						this.ViewBookEmployeestype = '2'
						this.initialValueArr = []
						res.data.list[1].value[1].data.forEach(it => {
							this.initialValueArr.push(Number(it))
						})
						this.changeitem()
					}

				})
			},
			getIndustry() {
				getIndustryType({
					data: {}
				}).then(res => {
					this.industryType = res.data.type
					this.scaleType = res.data.scale
				})
				getContractCompanySelect().then(res => {
					this.select_list = res.data.list
				})
			},
			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.img_code {
		height: 30px;
		width: 70px;
		margin-left: 12px;
		margin-right: 12px;
	}

	.text_code {
		height: 30px;
		width: 98px;
		margin-left: 12px;
		// margin-right: 12px;
	}

	.text_code2 {
		width: 130px;
	}

	.code_p {
		display: flex;
		margin-bottom: 12px;
	}

	.info {
		line-height: 44px;
	}

	.mailInfoEdit {
		padding: 32px;
		margin-top: 12px;
		background-color: #f8f8f9;
		border: 1px dashed #e1e6eb;
		border-radius: 4px;

		/deep/.ant-form-item {
			line-height: 32px;
		}
	}

	.chooseItem {
		border: 1px solid @cq_text_c6;
		color: @cq_text_c6;

		margin-bottom: 12px;
		text-align: center;
		border-radius: 6px;
		position: relative;
		padding: 0px 16px;
		border-radius: 6px;
		line-height: 32px;
		overflow: hidden;
		margin-right: 24px
	}

	.chooseItemC {
		border-color: @cq_color;
		color: @cq_color;

	}

	.chooseItemactieve {
		position: absolute;
		right: -1px;
		height: 24px;
		width: 24px;
		bottom: -1px;

	}

	.pointerCancel {
		padding: 0 12px;
	}

	.enterprise {
		padding: 0px;

	}
</style>